<template>
  <Doughnut
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { defineComponent, ref, toRefs, watch } from '@vue/composition-api';
import { Doughnut } from 'vue-chartjs/legacy';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale } from 'chart.js';
ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default defineComponent({
  components: {
    Doughnut,
  },
  props: {
    chartId: {
      type: String,
      default: 'doughnut-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    width: {
      type: Number,
      // default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: '',
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
    data: {
      type: [Array, Object],
      required: true,
    },
  },
  setup(props) {
    const expo = {};
    const { data } = toRefs(props);
    expo.chartData = ref({
      labels: [],
      datasets: [
        {
          backgroundColor: ['#1a9f53', '#be0ee1', '#2b77e5', '#e1940e'],
          data: [],
        },
      ],
    });

    expo.chartOptions = ref({
      responsive: true,
      maintainAspectRatio: false,
    });

    watch(
      data,
      (val) => {
        expo.chartData.value.labels = [];
        expo.chartData.value.datasets[0].data = [];
        val.forEach((el) => {
          expo.chartData.value.labels.push(el._id);
          expo.chartData.value.datasets[0].data.push(el.count);
        });
      },
      { deep: true, immediate: true }
    );

    return { ...expo };
  },
});
</script>
